import '../styles/globals.css'
import '@fontsource/lato/400.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/800.css'
import Script from 'next/script'
import { ChakraProvider } from '@chakra-ui/react'
import { mainTheme } from '../themes'

import { ApolloProvider } from '@apollo/client'
import client from '../utils/apollo-client'

import { Store } from '../store'

function MyApp({ Component, pageProps }) {
  return (
    <ChakraProvider theme={mainTheme}>
      <ApolloProvider client={client}>
        <Store>
          <Component {...pageProps} />
          <Script
            data-website-id='b3d5eaa8-2cf5-44e9-8b31-978919b9c05e'
            src='https://a.digvalley.com/script.js'
            strategy='afterInteractive'
          />

          <div id='fb-root'></div>
          <div id='fb-customer-chat' class='fb-customerchat'></div>

          <Script strategy='lazyOnload'>
            {`
              var chatbox = document.getElementById('fb-customer-chat');
      chatbox.setAttribute("page_id", "112540587910306");
      chatbox.setAttribute("attribution", "biz_inbox");
            `}
          </Script>

          <Script strategy='lazyOnload'>
            {`window.fbAsyncInit = function() {
        FB.init({
          xfbml            : true,
          version          : 'v14.0'
        });
      };

      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/da_DK/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
      `}
          </Script>
        </Store>
      </ApolloProvider>
    </ChakraProvider>
  )
}

export default MyApp
