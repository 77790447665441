import { createContext, useState } from 'react'

export const CartContext = createContext(null)
export const FilterContext = createContext(null)
export const ProductContext = createContext(null)
export const UiContext = createContext(null)

export const Store = ({ children }) => {
  const [cart, setCart] = useState({
    items: [],
    total: (cart) =>
      cart?.items?.length
        ? cart.items.reduce((a, c) => a + c.price * c.quantity, 0)
        : 0,
    shippingType: 'deliver',
  })
  const [filter, setFilter] = useState({
    brand: null,
    series: null,
    printer: null,
    color: null,
    compatible: null,
  })

  const [ui, setUi] = useState({ isSearching: false, searchTerm: null })

  return (
    <UiContext.Provider value={[ui, setUi]}>
      <CartContext.Provider value={[cart, setCart]}>
        <FilterContext.Provider value={[filter, setFilter]}>
          {children}
        </FilterContext.Provider>
      </CartContext.Provider>
    </UiContext.Provider>
  )
}
